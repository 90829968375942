import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Home.module.css';

interface WalletInfo {
  wallet: string;
  phase: string; // Adding phase to store what's in column B
}

const Map2: React.FC = () => {
  const [walletInput, setWalletInput] = useState('');
  const [walletStatus, setWalletStatus] = useState<string | null>(null);
  const [walletList, setWalletList] = useState<WalletInfo[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isWalletCheck, setIsWalletCheck] = useState(false); // To differentiate between wallet check and other popups
  const [tweetUrl, setTweetUrl] = useState<string | null>(null); // To store the Twitter URL
  const [showOverlay, setShowOverlay] = useState(false); // For the new overlay
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // For the image gallery
  const [currentParagraph, setCurrentParagraph] = useState(0); // To toggle paragraphs
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Array of gallery images
  const galleryImages = [
    '0.png',
    'argo1.jpg',
    'argo2.jpg',
    'argo3.jpg',
    'argo4.jpg',
    'argo5.jpg',
    'argo6.jpg',
    'argo7.jpg',
  ];

  // Full updated paragraphs
  const paragraphs = [
    `“Argo The Puppy” is the brainchild of Achilleas, illustrator and tattooer based in Athens Greece. Argo was designed originally for his fundraising project that supported dogs and animals during the wildfires of 2021 in Greece. Argo is already tattooed about four hundred times in La Santa Tinta, Achilleas' tattoo studio. The original sketch is based on Argo, a stray dog that Achilleas adopted in 2010 and became his faithful partner for 9 years until he passed away.

    The earnings from those tattoos went to Dogs' Voice, a non profit organization that focuses on the protection of stray animals in Greece. Dogs' Voice was founded in 2015 with the intention to support animal welfare organisations throughout the country, to promote stray animals in responsible adoptions and to implement programs that protect animals from neglect, abuse and uncontrolled breeding. Since 2017, Dogs' Voice has been involved in crisis management regarding the protection of animals in cases of natural disasters. In 2023, Dogs' Voice presented the action of “Advocates for Animals of Greece” with the mission of protecting animals through the legal system.
    The name Argo comes from Argos, the loyal dog of Odysseus. Argos was the one that waited patiently for Odysseus' return in Ithaca for 20 years while Odysseus and his crew were battling in the sea. He recognized him at first glance although Odysseus was disguised. He just had enough strength to drop his ears and wag his tail but could not get up to greet his master. Unable to greet his beloved dog, as this would betray who he really was, Odysseus passes by (but not without shedding a tear) and enters his hall, as Argos dies.

    Argo The Puppy, patron of the strays!`,
    `Now is the time for Argo to get on the blockchain. “Argo The Puppy” is a collection of 3333 unique pieces of Argo, with really funny traits and eye catching design.

    This will be an ordinals collection on BTC chain. Our original plan was to make a classic drop on Etherium but Elon likes his non fungible tokens on chain, so we have to abide. The exact link and date of mint will be announced on our X page and discord.

    10% of the mint revenue will go to Dogs' Voice.

    And now comes the sentence you have all been waiting for:

    “There are many plans for the future, this is just the first step of our journey.” :)

    The truth is that we really believe in the power of Argo. What can Argo be besides an inscription on the skin as well as an inscription on BTC? It can be a t-shirt, it can be a nice print on the wall, a plush toy... it can be a lot of things. All with a common denominator: Animal welfare.

    We have plans for IRL merchandise and further drops, but it all really depends on the success of this collection. We think that “Argo The Puppy” should be in the wallet of every collector or degen who is an animal lover and cares about the well-being of these unfortunate souls. We humans will always be responsible for the fate of them as well as the planet as a whole.`
  ];

  // Cycle through the images every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 2000); // Change image every 2 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [galleryImages.length]);

  // Toggle to next paragraph
  const nextParagraph = () => {
    setCurrentParagraph((prev) => (prev + 1) % paragraphs.length);
  };

  // Toggle to previous paragraph
  const prevParagraph = () => {
    setCurrentParagraph((prev) => (prev - 1 + paragraphs.length) % paragraphs.length);
  };

  const handleWalletCheck = () => {
    const walletInfo = walletList.find(item => item.wallet === walletInput.trim().toLowerCase());

    if (walletInfo) {
      setWalletStatus('onList');
      setPopupMessage(walletInfo.phase === 'GTD' ? 'You are on the Guaranteed list! Woof!' : `You are on the ${walletInfo.phase} list! Woof!`);
      const tweetMessage = `Woof! @argothepuppy is minting on Oct 3rd, 12:00 pm EST and I'm in!`;
      const imageUrl = encodeURIComponent('https://argothepuppy.org');
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetMessage)}&url=${imageUrl}`;
      setTweetUrl(url);
    } else {
      setWalletStatus('notOnList');
      setPopupMessage('Sorry, you are not on the list.');
      setTweetUrl(null);
    }
    setShowPopup(true);
    setIsWalletCheck(true); // Mark it as a wallet check popup
  };

  const handleComingSoonClick = (e: React.MouseEvent, message: string) => {
    e.preventDefault();
    if (audioRef.current) {
      audioRef.current.play();
    }
    setPopupMessage(message);
    setShowPopup(true);
    setIsWalletCheck(false); // Mark it as a regular popup
    setTimeout(() => setShowPopup(false), 2000);
  };

  // New Overlay Close Handler
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className={styles.homepage}>
      <audio ref={audioRef} src="/growl.mp3" preload="auto" />
      <svg className={styles.hybridMap} width="100%" height="100%" viewBox="0 0 1920 1080" preserveAspectRatio="none">
        <image href="/webnew.png" width="100%" height="1080" />

        {/* Medium link */}
        <a href="https://medium.com/@genxdegen0x/argo-the-puppy-social-club-76b206346cf1" target="_blank" rel="noopener noreferrer">
          <rect x="810" y="565" width="205" height="80" fill="transparent" opacity={0.5} />
        </a>

        {/* Twitter link */}
        <a href="https://x.com/argothepuppy" target="_blank" rel="noopener noreferrer">
          <rect x="755" y="485" width="225" height="75" fill="transparent" opacity={0.5} />
        </a>

        {/* Gallery link */}
        <a href="/gallery"  rel="noopener noreferrer">
          <rect x="780" y="650" width="195" height="75" fill="transparent" opacity={0.5} />
        </a>

        {/* Holders link */}
        <a href="/holders"  rel="noopener noreferrer">
          <rect x="1010" y="650" width="185" height="75" fill="transparent" opacity={0.5} />
        </a>


        {/* Discord link */}
        <a href="https://discord.com/invite/5KA64pKarU" target="_blank" rel="noopener noreferrer">
          <rect x="830" y="390" width="250" height="90" fill="transparent" opacity={0.5} />
        </a>

        {/* "Coming Soon" rect */}
        <a href="https://gamma.io/ordinals/collections/argo-the-puppy" target="_blank" rel="noopener noreferrer">
          <rect
            x="340"
            y="890"
            width="210"
            height="120"
            fill="transparent"
            opacity={0.5}
          />
        </a>


        {/* "ArgoD2 Bottom */}
        <a href="/ArgoD2" target="_blank" rel="noopener noreferrer">
          <rect
             x="810" // Position the text within the rectangle
             y="735" // Position the text vertically within the rectangle
             width="315"
             height="200"
             fill="transparent"
             opacity=".5"
          />
        </a>


        {/* "ArgoD2 Top */}
        <a href="/ArgoD2" target="_blank" rel="noopener noreferrer">
          <rect
             x="760" // Position the text within the rectangle
             y="25" // Position the text vertically within the rectangle
             width="400"
             height="350"
             fill="transparent"
             opacity=".5"
          />
        </a>

        {/* "ArgoD2 Left */}
        <a href="/ArgoD2" target="_blank" rel="noopener noreferrer">
          <rect
             x="275" // Position the text within the rectangle
             y="300" // Position the text vertically within the rectangle
             width="340"
             height="580"
             fill="transparent"
             opacity=".5"
          />
        </a>

        {/* Overlay trigger button */}
        <g>
          <rect
            x="1010" // Position the text within the rectangle
            y="485" // Position the text vertically within the rectangle
            width="175"
            height="75"
            fill="transparent"
            opacity=".5"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowOverlay(true)} // Open overlay on click  
          >
          </rect>
        </g>
      </svg>

      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '99vw',
            height: '99vh',
            backgroundColor: 'rgba(0, 0, 0, 1)', // Semi-transparent background
            zIndex: 1001,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '10px',
            boxSizing: 'border-box',
          }}
        >
          {/* Overlay content */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              opacity: '.8',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            {/* Image Gallery */}
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={galleryImages[currentImageIndex]}
                alt="Gallery"
                style={{
                  maxWidth: '75%',
                  maxHeight: '75%',
                  objectFit: 'cover',

                  transition: 'all 0.5s ease-in-out', // Smooth transition
                }}
              />
            </div>

            {/* Text Content */}
            <div
              style={{
                width: '45%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: '1dvw',
                lineHeight: '27px',
                color: "white",
                textAlign: 'justify'

              }}
            >
              <h2>Argo The Puppy & Achilleas The Artist!</h2>
              <p>{paragraphs[currentParagraph]}</p>
              <div style={{ position: 'relative', width: '100%', height: '128px', justifyContent:'center', display:'flex' }}>
              <img 
              src="/buttons.png" 
              alt="Navigation buttons" 
              style={{ 
                marginTop: '20px',
                width: 'auto', 
                height: '100%', // Maintain aspect ratio
                display: 'block', // Remove any extra space below the image
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '34%',
                width: '14%',
                height: '70%',
                cursor: 'pointer',
                
              }}
              onClick={prevParagraph}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '15%',
                height: '70%',
                cursor: 'pointer',
               
              }}
              onClick={nextParagraph}
            />
            <div
              style={{
                position: 'absolute',
                top: '75%',
                left: '38%',
                width: '25%',
                height: '57%',
                cursor: 'pointer',
                
              }}
              onClick={handleCloseOverlay}
            />
          </div>
        </div>
            </div>
          </div>
        
      )}

      {showPopup && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            backgroundColor: 'white',
            border: '2px solid black',
            borderRadius: '8px',
            zIndex: 1000,
            width: 'fit-content', // Use 90% width for smaller screens
            maxWidth: isWalletCheck ? '500px' : 'auto', // Adjust max-width for wallet check popup
          }}
        >
          {!walletStatus && isWalletCheck ? (
            <>
              <input
                type="text"
                value={walletInput}
                onChange={(e) => setWalletInput(e.target.value)}
                placeholder="Enter your wallet address"
                style={{ width: '100%', padding: '10px', marginBottom: '10px', boxSizing: 'border-box', fontSize: '20px' }} // Ensure width respects container
              />
              <button onClick={handleWalletCheck} style={{ padding: '10px', width: '100%', fontSize: '20px' }}>
                Check Wallet
              </button>
            </>
          ) : (
            <>
              <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>{popupMessage}</p>
              {walletStatus && isWalletCheck && (
                <>
                  <button onClick={() => setShowPopup(false)} style={{ padding: '10px', width: '100%', fontSize: '20px' }}>
                    Close
                  </button>
                  {tweetUrl && (
                    <a
                      href={tweetUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'block',
                        marginTop: '10px',
                        backgroundColor: '#1DA1F2',
                        color: 'white',
                        textAlign: 'center',
                        padding: '10px',
                        textDecoration: 'none',
                        borderRadius: '5px',
                        fontSize: '20px'
                      }}
                    >
                      Share on X!
                    </a>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {/* Optional overlay */}
      {showPopup && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default Map2;