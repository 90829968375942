import React, { useEffect, useState } from 'react';
import { getAddress, GetAddressOptions, AddressPurpose, BitcoinNetworkType, BitcoinProvider } from 'sats-connect';

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnect: (address: string, walletType: string) => void;
}

interface BtcAccount {
  address: string;
  addressType: "p2tr" | "p2wpkh" | "p2sh" | "p2pkh";
  publicKey: string;
  purpose: "payment" | "ordinals";
}

interface WalletProvider {
    request: (method: string, params: any) => Promise<any>;
  }

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, onClose, onConnect }) => {
  const [isPhantomInstalled, setIsPhantomInstalled] = useState(false);

  useEffect(() => {
    const checkPhantomInstallation = () => {
      const provider = getPhantomProvider();
      setIsPhantomInstalled(!!provider);
    };

    checkPhantomInstallation();
  }, []);

  const getPhantomProvider = () => {
    if ('phantom' in window) {
      const anyWindow: any = window;
      const provider = anyWindow.phantom?.bitcoin;
     
      if (provider && provider.isPhantom) {
        return provider;
      }
    }
    return null;
  };

  const connectWithSatsConnect = async (walletType: 'xverse' | 'magiceden') => {
    try {
      const getAddressOptions: GetAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (response) => {
          const ordinalsAddress = response.addresses.find(addr => addr.purpose === AddressPurpose.Ordinals);
          if (ordinalsAddress) {
            onConnect(ordinalsAddress.address, walletType);
            onClose();
          } else {
            console.error('No ordinals address found');
          }
        },
        onCancel: () => console.log('User canceled the request'),
      };

      // Use the correct wallet provider
      let getProvider: () => Promise<BitcoinProvider | undefined>;
      if (walletType === 'xverse') {
        getProvider = async () => (window as any).XverseProviders?.bitcoin as BitcoinProvider;
      } else if (walletType === 'magiceden') {
        // Assuming Magic Eden uses a similar provider injection
        getProvider = async () => (window as any).magicEdenProviders?.bitcoin as BitcoinProvider;
      } else {
        throw new Error(`Unsupported wallet type: ${walletType}`);
      }

      await getAddress({
        ...getAddressOptions,
        getProvider,
      });
    } catch (err) {
      console.error(`Error connecting ${walletType} wallet:`, err);
    }
  };

  const connectXverse = () => connectWithSatsConnect('xverse');
  const connectMagicEden = () => connectWithSatsConnect('magiceden');

  const connectPhantom = async () => {
    try {
      const provider = getPhantomProvider();
      if (provider) {
        const accounts: BtcAccount[] = await provider.requestAccounts();
        if (accounts.length > 0) {
          const ordinalsAccount = accounts.find(account => account.purpose === 'ordinals');
          if (ordinalsAccount) {
            onConnect(ordinalsAccount.address, 'phantom');
            onClose();
          } else {
            console.error('No ordinals address found');
          }
        } else {
          console.error('No accounts returned');
        }
      } else {
        window.open('https://phantom.app/', '_blank');
      }
    } catch (err) {
      console.error('Error connecting Phantom wallet:', err);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="wallet-modal-overlay">
      <div className="wallet-modal">
        <h2>Connect Wallet</h2>
        <button onClick={connectXverse}>Xverse</button>
        {isPhantomInstalled ? (
          <button onClick={connectPhantom}>Phantom</button>
        ) : (
          <button onClick={() => window.open('https://phantom.app/', '_blank')}>
            Install Phantom
          </button>
        )}
        <button onClick={connectMagicEden}>Magic Eden</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default WalletModal;