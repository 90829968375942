import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from '../styles/Gallery.module.css';

interface NFT {
  name: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
  inscriptionId: string;
}

const TOTAL_NFTS = 237; // Adjust this to the actual number of NFTs you have

const AllNFTsPage: React.FC = () => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [selectedNFT, setSelectedNFT] = useState<NFT | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [traitCategories, setTraitCategories] = useState<{ [key: string]: string[] }>({});
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  useEffect(() => {
    const loadNFTs = async () => {
      setLoading(true);
      setError(null);
      try {
        const loadedNFTs: NFT[] = [];
        const categories: { [key: string]: string[] } = {};

        for (let i = 1; i <= TOTAL_NFTS; i++) {
          const response = await fetch(`/metadata/${i}.json`);
          const data = await response.json();
          
          const nft: NFT = {
            name: data.name,
            image: `/testimages/${i}.png`,
            attributes: data.attributes,
            inscriptionId: data.id
          };

          loadedNFTs.push(nft);

          nft.attributes.forEach(attr => {
            if (!categories[attr.trait_type]) {
              categories[attr.trait_type] = [];
            }
            if (!categories[attr.trait_type].includes(attr.value)) {
              categories[attr.trait_type].push(attr.value);
            }
          });
        }

        setNfts(loadedNFTs);
        setTraitCategories(categories);
      } catch (err) {
        console.error('Error loading Argos:', err);
        setError('Failed to load Argos. Please refresh the page to try again.');
      } finally {
        setLoading(false);
      }
    };

    loadNFTs();
  }, []);

  const toggleFilter = (traitType: string, value: string) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (!newFilters[traitType]) {
        newFilters[traitType] = [];
      }
      const index = newFilters[traitType].indexOf(value);
      if (index > -1) {
        newFilters[traitType] = newFilters[traitType].filter(v => v !== value);
        if (newFilters[traitType].length === 0) {
          delete newFilters[traitType];
        }
      } else {
        newFilters[traitType] = [...newFilters[traitType], value];
      }
      return newFilters;
    });
  };

  const filteredNFTs = nfts.filter(nft => {
    return Object.entries(filters).every(([traitType, values]) => {
      return nft.attributes.some(attr => 
        attr.trait_type === traitType && values.includes(attr.value)
      );
    });
  });

  const openNFTDetails = (nft: NFT) => {
    setSelectedNFT(nft);
  };

  const closeNFTDetails = () => {
    setSelectedNFT(null);
  };

  const toggleMobileFilter = () => {
    setShowMobileFilter(!showMobileFilter);
  };

  // Lazy loading implementation
  const observerRef = useRef<IntersectionObserver | null>(null);
  const imagesRef = useRef<Map<string, HTMLImageElement>>(new Map());

  const loadImage = useCallback((target: HTMLImageElement) => {
    const src = target.getAttribute('data-src');
    if (src) {
      target.src = src;
      target.removeAttribute('data-src');
    }
  }, []);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage(entry.target as HTMLImageElement);
            observerRef.current?.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '100px' }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [loadImage]);

  useEffect(() => {
    imagesRef.current.forEach((img) => {
      if (img && observerRef.current) {
        observerRef.current.observe(img);
      }
    });
  }, [filteredNFTs]);

  const setImageRef = useCallback((node: HTMLImageElement | null, inscriptionId: string) => {
    if (node) {
      imagesRef.current.set(inscriptionId, node);
    } else {
      imagesRef.current.delete(inscriptionId);
    }
  }, []);

  if (loading) return (
    <div className={styles.loadingContainer}>
      <div className={styles.spinner}></div>
      <h1 className={styles.loadingScreen}>Loading Argos... This may take a moment.</h1>
    </div>
  );
  if (error) return <div>{error}</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.galleryPage}>
      <div className={styles.titlesection}>
        <a className={styles.homebutton} href="/">Home</a>
        <h1 className={styles.galleryTitle}>Argo The Puppy Gallery</h1>
      </div>
      <div className={styles.galleryContent}>
        <div className={`${styles.filterPanel} ${showMobileFilter ? styles.showMobileFilter : ''}`}>
          <h2 className={styles.filterTitle}>Filters</h2>
          {Object.entries(traitCategories).map(([category, values]) => (
            <div key={category} className={styles.filterGroup}>
              <h3 className={styles.filterCategoryTitle}>{category}</h3>
              {values.map(value => (
                <label key={value} className={styles.filterCheckbox}>
                  <input
                    type="checkbox"
                    checked={filters[category]?.includes(value) || false}
                    onChange={() => toggleFilter(category, value)}
                  />
                  <span className={styles.filterLabel}>{value}</span>
                </label>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.nftGridContainer}>
          <div className={styles.nftGrid}>
            {filteredNFTs.map((nft) => (
              <div key={nft.inscriptionId} className={styles.nftCard} onClick={() => openNFTDetails(nft)}>
                <img
                  ref={(node) => setImageRef(node, nft.inscriptionId)}
                  data-src={nft.image}
                  alt={nft.name}
                  className={styles.nftImage}
                />
                <h3 className={styles.nftName}>{nft.name}</h3>
              </div>
            ))}
          </div>
          {filteredNFTs.length === 0 && <div>No Argos found matching the current filters.</div>}
        </div>
      </div>

      {selectedNFT && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={closeNFTDetails}>&times;</span>
            <div className={styles.modalBody}>
              <div className={styles.nftContentWrapper}>
                <div className={styles.modalImageContainer}>
                  <img 
                    src={selectedNFT.image.replace('/testimages/', '/argoimages/')} 
                    alt={selectedNFT.name} 
                    className={styles.modalImage} 
                  />
                  <h2 className={styles.modalTitle}>{selectedNFT.name}</h2>
                </div>
                <div className={styles.modalInfo}>
                  <h3 className={styles.traitsTitle}>Traits:</h3>
                  <div className={styles.traitsGrid}>
                    {selectedNFT.attributes.map((trait, index) => (
                      <div key={index} className={styles.traitItem}>
                        <strong>{trait.trait_type}:</strong> {trait.value}
                      </div>
                    ))}
                  </div>
                  <div className={styles.inscriptionInfo}>
                    <p>Inscription ID: {selectedNFT.inscriptionId}</p>
                    <a 
                      href={`https://ordiscan.com/inscription/${selectedNFT.inscriptionId}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className={styles.ordiscanButton}
                    >
                      Ordiscan
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <button className={styles.mobileFilterToggle} onClick={toggleMobileFilter}>
        {showMobileFilter ? 'Close Filter' : 'Open Filter'}
      </button>
    </div>
  );
};

export default AllNFTsPage;