import React, { useState, useEffect } from 'react';
import { request, AddressPurpose, BitcoinNetworkType, RpcErrorCode  } from 'sats-connect';
import Papa from 'papaparse';
import './OrdinalsWalletPage.css';
import WalletModal from './WalletModal';

interface Inscription {
  inscriptionId: string;
}

interface Metadata {
  id: string;
  name: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
  inscriptionId: string;
}

interface InscriptionResponse {
  id: string;
  number: number;
  address: string | null;
  genesis_address: string | null;
  genesis_block_height: number;
  genesis_block_hash: string;
  genesis_tx_id: string;
  genesis_fee: string;
  genesis_timestamp: number;
  tx_id: string;
  location: string;
  // Add other fields as needed
}

interface PaginatedInscriptionsResponse {
  limit: number;
  offset: number;
  total: number;
  results: InscriptionResponse[];
}

interface MatchedIdEntry {
  inscription_id: string;
  json_file: string;
  image_file: string;
}
interface BtcAccount {
  address: string;
  addressType: "p2tr" | "p2wpkh" | "p2sh" | "p2pkh";
  publicKey: string;
  purpose: "payment" | "ordinals";
}

const inscriptionIdsFilePath = '/inscription_ids.json';
const matchedIdsFilePath = '/matchedids.csv';
const metadataBasePath = '/metadata/';
const argosImageBasePath = '/argoimages/';

const XverseOrdinals: React.FC = () => {
  const [connected, setConnected] = useState(false);
  const [ordinalAddress, setOrdinalAddress] = useState('');
  const [filteredInscriptions, setFilteredInscriptions] = useState<Inscription[]>([]);
  const [ownedNFTs, setOwnedNFTs] = useState<Metadata[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedNFT, setSelectedNFT] = useState<Metadata | null>(null);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [connectedWalletType, setConnectedWalletType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
    if (connected && ordinalAddress && connectedWalletType) {
      console.log('Wallet connected, fetching Argos...');
      fetchInscriptionsWithRetry();
    }
  }, [connected, ordinalAddress, connectedWalletType]);

  const fetchInscriptionIds = async () => {
    try {
      const response = await fetch(inscriptionIdsFilePath);
      const data: string[] = await response.json();
      console.log("Fetched inscription IDs: ", data);
      return data;
    } catch (err) {
      console.error('Error fetching inscription_ids.json:', err);
      return [];
    }
  };

  

  const disconnectWallet = async () => {
    try {
      console.log('Attempting to disconnect wallet...');
      const response = await request('wallet_renouncePermissions', undefined);
      if (response.status === 'success') {
        setOrdinalAddress('');
        setConnected(false);
        setOwnedNFTs([]);
        setError(null);
        console.log('Wallet disconnected successfully');
      } else {
        throw new Error('Failed to disconnect wallet');
      }
    } catch (err) {
      setError('Error disconnecting wallet: ' + (err instanceof Error ? err.message : String(err)));
      console.error('Error in disconnectWallet:', err);
    }
  };

  const handleWalletConnect = (address: string, walletType: string) => {
  console.log(`Attempting to connect wallet. Type: ${walletType}, Address: ${address}`);
  setOrdinalAddress(address);
  setConnected(true);
  setConnectedWalletType(walletType);
  console.log(`Wallet connected successfully. Type: ${walletType}, Address: ${address}`);
  // The useEffect hook will trigger fetchInscriptionsWithRetry
};
  

  const handleDisconnect = () => {
    setOrdinalAddress('');
    setConnected(false);
    setConnectedWalletType(null);
    setOwnedNFTs([]);
    setError(null);
  };

  const openWalletModal = () => {
    setIsWalletModalOpen(true);
  };

  const closeWalletModal = () => {
    setIsWalletModalOpen(false);
  };

  const fetchInscriptions = async (): Promise<void> => {
    setIsLoading(true);
    if (!connected || !ordinalAddress) {
      console.error('Attempted to fetch inscriptions while disconnected');
      setError('Wallet is not connected. Please connect your wallet and try again.');
      setIsLoading(false);
      return;
    }
  
    try {
      console.log(`Fetching inscriptions for ${connectedWalletType}...`);
      let fetchedInscriptions: Inscription[] = [];
  
      if (connectedWalletType === 'xverse') {
        try {
          const response = await request('ord_getInscriptions', {
            offset: 0,
            limit: 100,
          });
  
          console.log('ord_getInscriptions response:', response);
  
          if (response.status === 'success') {
            fetchedInscriptions = response.result.inscriptions;
          } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
            console.log('Access denied. Requesting permissions...');
            
            // Request permissions from xverse wallet
            const permissionResponse = await request('wallet_requestPermissions', undefined);
            
            if (permissionResponse.status === 'success') {
              console.log('Permissions granted. Retrying fetchInscriptions...');
              setIsLoading(false);
              return fetchInscriptions(); // Retry after permissions are granted
            } else {
              throw new Error('User declined to grant permissions for fetching inscriptions');
            }
          } else {
            throw new Error(`Failed to fetch inscriptions: ${response.error.message}`);
          }
        } catch (err) {
          console.error('Error during fetching inscriptions:', err);
          throw err;
        }
      } else if (connectedWalletType === 'phantom' || connectedWalletType === 'magiceden') {
        // Use the same logic for fetching from API for both Phantom and Magic Eden
        fetchedInscriptions = await fetchInscriptionsFromAPI(ordinalAddress, 0, 60);
      } else {
        throw new Error('Unknown wallet type');
      }
  
      console.log('Fetched inscriptions from wallet: ', fetchedInscriptions);
  
      const filteredInscriptions = await filterInscriptions(fetchedInscriptions);
      const idToFileMapping = await fetchMatchedIds();
      await loadNFTs(idToFileMapping, filteredInscriptions);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(`Error fetching inscriptions: ${errorMessage}`);
      console.error('Error in fetchInscriptions:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInscriptionsFromAPI = async (address: string, offset: number, limit: number): Promise<Inscription[]> => {
    try {
      const response = await fetch(`https://api.hiro.so/ordinals/v1/inscriptions?address=${address}&limit=${limit}&offset=${offset}`);
      if (!response.ok) {
        throw new Error('Failed to fetch inscriptions from Hiro API');
      }
      const data: PaginatedInscriptionsResponse = await response.json();
      return data.results.map((item: InscriptionResponse) => ({
        inscriptionId: item.id,
        // Map other properties as needed to match your Inscription interface
      }));
    } catch (error) {
      console.error('Error fetching inscriptions from Hiro API:', error);
      throw error;
    }
  };

  const fetchInscriptionsWithRetry = async (retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        await fetchInscriptions();
        return; // If successful, exit the function
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === retries - 1) {
          // If this was the last attempt, set the error state
          setError(`Failed to fetch inscriptions after ${retries} attempts. Please try again later.`);
        } else {
          // If not the last attempt, wait before trying again
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        }
      }
    }
  };

  const fetchMatchedIds = async () => {
    return new Promise<{ [key: string]: { json_file: string; image_file: string } }>((resolve, reject) => {
      Papa.parse(matchedIdsFilePath, {
        download: true,
        header: true,
        complete: (results) => {
          const mapping: { [key: string]: { json_file: string; image_file: string } } = {};
          results.data.forEach((row: unknown) => {
            const matchedEntry = row as MatchedIdEntry;
            mapping[matchedEntry.inscription_id] = {
              json_file: matchedEntry.json_file,
              image_file: matchedEntry.image_file,
            };
          });
          console.log("Fetched matched IDs: ", mapping);
          resolve(mapping);
        },
        error: (error) => {
          console.error('Error parsing matchedids.csv:', error);
          reject(error);
        },
      });
    });
  };

  const filterInscriptions = async (fetchedInscriptions: Inscription[]) => {
    const validInscriptionIds = await fetchInscriptionIds();
    const filtered = fetchedInscriptions.filter(inscription =>
      validInscriptionIds.includes(inscription.inscriptionId)
    );
    console.log("Filtered inscriptions: ", filtered);
    setFilteredInscriptions(filtered);
    return filtered;
  };

  const loadNFTs = async (idToFileMap: { [key: string]: { json_file: string; image_file: string } }, inscriptions: Inscription[]) => {
    const nfts: Metadata[] = [];

    for (const inscription of inscriptions) {
      const matchedFiles = idToFileMap[inscription.inscriptionId];
      if (matchedFiles) {
        console.log(`Matched Inscription ID ${inscription.inscriptionId}:`, matchedFiles);

        try {
          const response = await fetch(`${metadataBasePath}${matchedFiles.json_file.split('/').pop()}`);
          if (!response.ok) {
            console.error(`Failed to fetch metadata: ${matchedFiles.json_file}`);
            continue;
          }
          const metadata = await response.json();
          nfts.push({
            ...metadata,
            image: `${argosImageBasePath}${matchedFiles.image_file.split('/').pop()}`,
            inscriptionId: inscription.inscriptionId, // Ensure we're including the inscriptionId
          });
          console.log("Fetched NFT metadata:", metadata);
        } catch (err) {
          console.error(`Error fetching metadata for ${inscription.inscriptionId}:`, err);
        }
      } else {
        console.error(`No match for inscription ID: ${inscription.inscriptionId}`);
      }
    }

    setOwnedNFTs(nfts);
    console.log("Final owned Argos: ", nfts);
  };

  const connectWallet = async () => {
    try {
      console.log('Attempting to connect wallet...');
      const response = await request('getAccounts', {
        purposes: ['ordinals' as AddressPurpose],
        message: 'Connect to view your ordinals',
      });
  
      if (response.status === 'success') {
        handleSuccessfulConnection(response);
      } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
        console.log('Access denied. Requesting permissions...');
        const permissionResponse = await request('wallet_requestPermissions', undefined);
        
        if (permissionResponse.status === 'success') {
          console.log('Permissions granted. Retrying getAccounts...');
          const retryResponse = await request('getAccounts', {
            purposes: ['ordinals' as AddressPurpose],
            message: 'Connect to view your ordinals',
          });
  
          if (retryResponse.status === 'success') {
            handleSuccessfulConnection(retryResponse);
          } else {
            throw new Error('Failed to get accounts after granting permissions');
          }
        } else {
          throw new Error('User declined to grant permissions');
        }
      } else {
        throw new Error('Failed to connect wallet');
      }
    } catch (err) {
      setError('Error connecting wallet: ' + (err instanceof Error ? err.message : String(err)));
      console.error('Error in connectWallet:', err);
    }
  };
  
  const handleSuccessfulConnection = (response: any) => {
    const ordinalsAddressItem = response.result[0];
    if (ordinalsAddressItem) {
      console.log('Wallet connected successfully:', ordinalsAddressItem);
      setOrdinalAddress(ordinalsAddressItem.address);
      setConnected(true);
  
      // Set the wallet type based on the connected wallet
      if (response.walletType === 'xverse') {
        setConnectedWalletType('xverse');
      } else if (response.walletType === 'magiceden') {
        setConnectedWalletType('magiceden');
      } else {
        setConnectedWalletType('unknown');
      }
    } else {
      setError('No ordinals address found');
    }
  };



  const openNFTDetails = (nft: Metadata) => {
    setSelectedNFT(nft);
  };

  const closeNFTDetails = () => {
    setSelectedNFT(null);
  };

  return (
    <div className="ordinals-wallet-page">
      <div className="argotitlesection">
        <a className="argohomebutton" href="/">Home</a>
        <h1 className="argotitle">My Argo The Puppy Kennel</h1>
      </div>
      {!connected ? (
        <button onClick={openWalletModal} className="connect-button">Connect Wallet</button>
      ) : (
        <div>
          <p className="mb-2">Connected Address: {ordinalAddress}</p>
          <button onClick={handleDisconnect} className="disconnect-button">Disconnect Wallet</button>
          <h2 className="text-xl font-semibold mb-7">Your Argos</h2>
          {isLoading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Loading your Argos...</p>
            </div>
          ) : ownedNFTs.length > 0 ? (
            <div className="gallery">
              {ownedNFTs.map((nft, index) => (
                <div
                  key={index}
                  className="nft-item"
                  onClick={() => setSelectedNFT(nft)}
                >
                  <img src={nft.image} alt={nft.name} />
                  <h3 className="font-bold text-center mt-2">{nft.name}</h3>
                </div>
              ))}
            </div>
          ) : (
            <p>No Argos found.</p>
          )}
        </div>
      )}
      {error && <p className="text-red-500 mt-4">{error}</p>}
  
      <WalletModal
        isOpen={isWalletModalOpen}
        onClose={closeWalletModal}
        onConnect={handleWalletConnect}
      />
  
      {selectedNFT && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeNFTDetails}>&times;</span>
  
            <div className="nft-details">
              <div className="nft-content-wrapper">
                <div className="nft-image-container">
                  <img src={selectedNFT.image} alt={selectedNFT.name} className="nft-image" />
                  <h2 className="nft-name">{selectedNFT.name}</h2>
                </div>
                <div className="nft-traits">
                  <h3>Traits:</h3>
                  <div className="traits-grid">
                    {selectedNFT.attributes.map((trait, index) => (
                      <div key={index} className="trait">
                        <strong>{trait.trait_type}:</strong> {trait.value}
                      </div>
                    ))}
                  </div>
                  <div className="inscription-info">
                    <p>Inscription ID: {selectedNFT.inscriptionId}</p>
                    {selectedNFT.inscriptionId && (
                      <a
                        href={`https://ordiscan.com/inscription/${selectedNFT.inscriptionId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ordiscan-button"
                      >
                        Ordiscan
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default XverseOrdinals;