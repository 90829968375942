import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import axios from 'axios';
import styles from './ChatComponent.module.css';
import ChartTool from './ChartTool';
import DexScreenerEmbed from './DexScreenerEmbed';
import WatchlistPanel from './Watchlist';
import WalletModal from './WalletModal';
import Papa from 'papaparse';
import { request, AddressPurpose, BitcoinNetworkType, RpcErrorCode } from 'sats-connect';
import ConversationLibrary from './ConversationLibrary';

const API_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:5008' : 'https://api.argothepuppy.org');

interface Inscription {
  inscriptionId: string;
}

interface Metadata {
  id: string;
  name: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
  inscriptionId: string;
}

interface Message {
  agent: string;
  message: string;
  timestamp?: string;
}

interface AgentOutput {
  id: string;
  name: string;
  output: string;
  timestamp: string;
}

interface InscriptionResponse {
  id: string;
  number: number;
  address: string | null;
  genesis_address: string | null;
  genesis_block_height: number;
  genesis_block_hash: string;
  genesis_tx_id: string;
  genesis_fee: string;
  genesis_timestamp: number;
  tx_id: string;
  location: string;
  // Add other fields as needed
}

interface PaginatedInscriptionsResponse {
  limit: number;
  offset: number;
  total: number;
  results: InscriptionResponse[];
}

interface MatchedIdEntry {
  inscription_id: string;
  json_file: string;
  image_file: string;
}
interface BtcAccount {
  address: string;
  addressType: "p2tr" | "p2wpkh" | "p2sh" | "p2pkh";
  publicKey: string;
  purpose: "payment" | "ordinals";
}

const inscriptionIdsFilePath = '/inscription_ids.json';
const matchedIdsFilePath = '/matchedids.csv';
const metadataBasePath = '/metadata/';
const argosImageBasePath = '/argoimages/';


const ChatComponent: React.FC = () => {
  const [messages, setMessages] = useState<Array<{ agent: string; message: string }>>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isConversationActive, setIsConversationActive] = useState(false);
  const [isReviewerEnabled, setIsReviewerEnabled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isThinking, setIsThinking] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [showChartTool, setShowChartTool] = useState(false);
  const [showLibrary, setShowLibrary] = useState(true);
  const [showDexScreener, setShowDexScreener] = useState(false);
  const [dexScreenerContract, setDexScreenerContract] = useState('');
  const [dexScreenerChain, setDexScreenerChain] = useState('ethereum');
  const [showMemeChartPopup, setShowMemeChartPopup] = useState(false);
  const [memeChartContract, setMemeChartContract] = useState('');
  const [memeChartChain, setMemeChartChain] = useState('ethereum');
  const [showDexScreenerPopup, setShowDexScreenerPopup] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [showTokenDataPopup, setShowTokenDataPopup] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [currentRequest, setCurrentRequest] = useState<AbortController | null>(null);
  const [voiceEnabled, setVoiceEnabled] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [chartSymbol, setChartSymbol] = useState<string>('BTCUSD');
  const [chartType, setChartType] = useState<'stock' | 'crypto'>('crypto');
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [connectedWalletType, setConnectedWalletType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [connected, setConnected] = useState(false);
  const [ordinalAddress, setOrdinalAddress] = useState('');
  const [filteredInscriptions, setFilteredInscriptions] = useState<Inscription[]>([]);
  const [ownedNFTs, setOwnedNFTs] = useState<Metadata[]>([]);
  const [currentConversationId, setCurrentConversationId] = useState<string | null>(null);
  const [messageHistory, setMessageHistory] = useState<Array<{ role: string; content: string }>>([]);
  const [isCurrentAgentA, setIsCurrentAgentA] = useState<boolean>(true);
  const [isAgentMode, setIsAgentMode] = useState(false);
  const [agentConversation, setAgentConversation] = useState<Array<{ agent: string; message: string }>>([]);
  const [isAgentTaskComplete, setIsAgentTaskComplete] = useState(false);
  const [currentAgent, setCurrentAgent] = useState<'A' | 'B'>('A');
  const [agentOutputs, setAgentOutputs] = useState<AgentOutput[]>([]);
  const [normalInput, setNormalInput] = useState('');  // For regular chat
  const [agentInput, setAgentInput] = useState('');   // For agent chat
  const [isAgentThinking, setIsAgentThinking] = useState(false);
  const [selectedAgentOutput, setSelectedAgentOutput] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);



  useEffect(() => {
    if (connected && ordinalAddress && connectedWalletType) {
      console.log('Wallet connected, fetching Argos...');
      fetchInscriptionsWithRetry();
    }
  }, [connected, ordinalAddress, connectedWalletType]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown if clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  // Close dropdown after clicking an option
  const handleOptionClick = (action: () => void) => {
    action();
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleMouseDown = (event: Event) => handleClickOutside(event as unknown as MouseEvent);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);



  const fetchInscriptionIds = async () => {
    try {
      const response = await fetch(inscriptionIdsFilePath);
      const data: string[] = await response.json();
      console.log("Fetched inscription IDs: ", data);
      return data;
    } catch (err) {
      console.error('Error fetching inscription_ids.json:', err);
      return [];
    }
  };


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const disconnectWallet = async () => {
    try {
      console.log('Attempting to disconnect wallet...');
      const response = await request('wallet_renouncePermissions', undefined);
      if (response.status === 'success') {
        setOrdinalAddress('');
        setConnected(false);
        setOwnedNFTs([]);
        setError(null);
        console.log('Wallet disconnected successfully');
      } else {
        throw new Error('Failed to disconnect wallet');
      }
    } catch (err) {
      setError('Error disconnecting wallet: ' + (err instanceof Error ? err.message : String(err)));
      console.error('Error in disconnectWallet:', err);
    }
  };

  const handleWalletConnect = (address: string, walletType: string) => {
    console.log(`Attempting to connect wallet. Type: ${walletType}, Address: ${address}`);
    setOrdinalAddress(address);
    setConnected(true);
    setConnectedWalletType(walletType);
    console.log(`Wallet connected successfully. Type: ${walletType}, Address: ${address}`);
    // The useEffect hook will trigger fetchInscriptionsWithRetry
  };

  const handleViewAgentOutput = (outputContent: string) => {
    console.log("Setting selectedAgentOutput:", outputContent);  // Debug log
    setSelectedAgentOutput(outputContent);
  };
  const handleDisconnect = () => {
    setOrdinalAddress('');
    setConnected(false);
    setConnectedWalletType(null);
    setOwnedNFTs([]);
    setError(null);
  };

  const openWalletModal = () => {
    setIsWalletModalOpen(true);
  };

  const closeWalletModal = () => {
    setIsWalletModalOpen(false);
  };

  const closeAgentModal = () => {
    setIsAgentMode(false);
    setAgentConversation([]);
    setIsAgentTaskComplete(false);
    setCurrentAgent('A');
    setIsThinking(false);
    setAgentInput('');
  };



  const fetchAgentOutputs = async () => {
    try {
      const response = await fetch(`${API_URL}/api/agent-outputs/${ordinalAddress}`);
      if (response.ok) {
        const data = await response.json();
        // Check each item to ensure it has the `output` field
        const formattedData = data.map((item: any) => ({
          id: item.id,
          name: item.name,
          output: item.output || "",  // Ensure `output` is defined
          timestamp: item.timestamp,
        }));
        setAgentOutputs(formattedData);
      } else {
        console.error('Failed to fetch agent outputs:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching agent outputs:', error);
    }
  };

  const fetchInscriptions = async (): Promise<void> => {
    setIsLoading(true);
    if (!connected || !ordinalAddress) {
      console.error('Attempted to fetch inscriptions while disconnected');
      setError('Wallet is not connected. Please connect your wallet and try again.');
      setIsLoading(false);
      return;
    }

    try {
      console.log(`Fetching inscriptions for ${connectedWalletType}...`);
      let fetchedInscriptions: Inscription[] = [];

      if (connectedWalletType === 'xverse') {
        try {
          const response = await request('ord_getInscriptions', {
            offset: 0,
            limit: 100,
          });

          console.log('ord_getInscriptions response:', response);

          if (response.status === 'success') {
            fetchedInscriptions = response.result.inscriptions;
          } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
            console.log('Access denied. Requesting permissions...');

            // Request permissions from xverse wallet
            const permissionResponse = await request('wallet_requestPermissions', undefined);

            if (permissionResponse.status === 'success') {
              console.log('Permissions granted. Retrying fetchInscriptions...');
              setIsLoading(false);
              return fetchInscriptions(); // Retry after permissions are granted
            } else {
              throw new Error('User declined to grant permissions for fetching inscriptions');
            }
          } else {
            throw new Error(`Failed to fetch inscriptions: ${response.error.message}`);
          }
        } catch (err) {
          console.error('Error during fetching inscriptions:', err);
          throw err;
        }
      } else if (connectedWalletType === 'phantom' || connectedWalletType === 'magiceden') {
        // Use the same logic for fetching from API for both Phantom and Magic Eden
        fetchedInscriptions = await fetchInscriptionsFromAPI(ordinalAddress, 0, 60);
      } else {
        throw new Error('Unknown wallet type');
      }

      console.log('Fetched inscriptions from wallet: ', fetchedInscriptions);

      const filteredInscriptions = await filterInscriptions(fetchedInscriptions);
      const idToFileMapping = await fetchMatchedIds();
      await loadNFTs(idToFileMapping, filteredInscriptions);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(`Error fetching inscriptions: ${errorMessage}`);
      console.error('Error in fetchInscriptions:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadConversation = async (conversationId: string, messages: any[]) => {
    try {
      // Clear agent output to make room for the regular conversation
      setSelectedAgentOutput(null);

      // Clear previous message history
      setMessageHistory([]);
      setCurrentConversationId(conversationId);

      // Transform and set messages
      const formattedMessages = messages.map(msg => ({
        agent: msg.role === 'user' ? 'user' : 'Assistant',
        message: msg.content
      }));

      // Update message history for the loaded conversation
      const historyMessages = messages.map(msg => ({
        role: msg.role,
        content: msg.content
      }));
      setMessageHistory(historyMessages);

      setMessages(formattedMessages);
      setIsConversationActive(true);
    } catch (error) {
      console.error('Error loading conversation:', error);
      setError('Failed to load conversation');
    }
  };

  const fetchInscriptionsFromAPI = async (address: string, offset: number, limit: number): Promise<Inscription[]> => {
    try {
      const response = await fetch(`https://api.hiro.so/ordinals/v1/inscriptions?address=${address}&limit=${limit}&offset=${offset}`);
      if (!response.ok) {
        throw new Error('Failed to fetch inscriptions from Hiro API');
      }
      const data: PaginatedInscriptionsResponse = await response.json();
      return data.results.map((item: InscriptionResponse) => ({
        inscriptionId: item.id,
        // Map other properties as needed to match your Inscription interface
      }));
    } catch (error) {
      console.error('Error fetching inscriptions from Hiro API:', error);
      throw error;
    }
  };

  const fetchInscriptionsWithRetry = async (retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        await fetchInscriptions();
        return; // If successful, exit the function
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === retries - 1) {
          // If this was the last attempt, set the error state
          setError(`Failed to fetch inscriptions after ${retries} attempts. Please try again later.`);
        } else {
          // If not the last attempt, wait before trying again
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        }
      }
    }
  };

  const fetchMatchedIds = async () => {
    return new Promise<{ [key: string]: { json_file: string; image_file: string } }>((resolve, reject) => {
      Papa.parse(matchedIdsFilePath, {
        download: true,
        header: true,
        complete: (results) => {
          const mapping: { [key: string]: { json_file: string; image_file: string } } = {};
          results.data.forEach((row: unknown) => {
            const matchedEntry = row as MatchedIdEntry;
            mapping[matchedEntry.inscription_id] = {
              json_file: matchedEntry.json_file,
              image_file: matchedEntry.image_file,
            };
          });
          console.log("Fetched matched IDs: ", mapping);
          resolve(mapping);
        },
        error: (error) => {
          console.error('Error parsing matchedids.csv:', error);
          reject(error);
        },
      });
    });
  };

  const filterInscriptions = async (fetchedInscriptions: Inscription[]) => {
    const validInscriptionIds = await fetchInscriptionIds();
    const filtered = fetchedInscriptions.filter(inscription =>
      validInscriptionIds.includes(inscription.inscriptionId)
    );
    console.log("Filtered inscriptions: ", filtered);
    setFilteredInscriptions(filtered);
    return filtered;
  };

  const loadNFTs = async (idToFileMap: { [key: string]: { json_file: string; image_file: string } }, inscriptions: Inscription[]) => {
    const nfts: Metadata[] = [];

    for (const inscription of inscriptions) {
      const matchedFiles = idToFileMap[inscription.inscriptionId];
      if (matchedFiles) {
        console.log(`Matched Inscription ID ${inscription.inscriptionId}:`, matchedFiles);

        try {
          const response = await fetch(`${metadataBasePath}${matchedFiles.json_file.split('/').pop()}`);
          if (!response.ok) {
            console.error(`Failed to fetch metadata: ${matchedFiles.json_file}`);
            continue;
          }
          const metadata = await response.json();
          nfts.push({
            ...metadata,
            image: `${argosImageBasePath}${matchedFiles.image_file.split('/').pop()}`,
            inscriptionId: inscription.inscriptionId, // Ensure we're including the inscriptionId
          });
          console.log("Fetched NFT metadata:", metadata);
        } catch (err) {
          console.error(`Error fetching metadata for ${inscription.inscriptionId}:`, err);
        }
      } else {
        console.error(`No match for inscription ID: ${inscription.inscriptionId}`);
      }
    }

    setOwnedNFTs(nfts);
    console.log("Final owned Argos: ", nfts);
  };

  const connectWallet = async () => {
    try {
      console.log('Attempting to connect wallet...');
      const response = await request('getAccounts', {
        purposes: ['ordinals' as AddressPurpose],
        message: 'Connect to view your ordinals',
      });

      if (response.status === 'success') {
        handleSuccessfulConnection(response);
      } else if (response.error.code === RpcErrorCode.ACCESS_DENIED) {
        console.log('Access denied. Requesting permissions...');
        const permissionResponse = await request('wallet_requestPermissions', undefined);

        if (permissionResponse.status === 'success') {
          console.log('Permissions granted. Retrying getAccounts...');
          const retryResponse = await request('getAccounts', {
            purposes: ['ordinals' as AddressPurpose],
            message: 'Connect to view your ordinals',
          });

          if (retryResponse.status === 'success') {
            handleSuccessfulConnection(retryResponse);
          } else {
            throw new Error('Failed to get accounts after granting permissions');
          }
        } else {
          throw new Error('User declined to grant permissions');
        }
      } else {
        throw new Error('Failed to connect wallet');
      }
    } catch (err) {
      setError('Error connecting wallet: ' + (err instanceof Error ? err.message : String(err)));
      console.error('Error in connectWallet:', err);
    }
  };

  const handleSuccessfulConnection = (response: any) => {
    const ordinalsAddressItem = response.result[0];
    if (ordinalsAddressItem) {
      console.log('Wallet connected successfully:', ordinalsAddressItem);
      setOrdinalAddress(ordinalsAddressItem.address);
      setConnected(true);

      // Set the wallet type based on the connected wallet
      if (response.walletType === 'xverse') {
        setConnectedWalletType('xverse');
      } else if (response.walletType === 'magiceden') {
        setConnectedWalletType('magiceden');
      } else {
        setConnectedWalletType('unknown');
      }
    } else {
      setError('No ordinals address found');
    }
  };

  const handleNewChat = async () => {
    setMessages([]);
    setMessageHistory([]);
    setCurrentConversationId(null);
    setIsConversationActive(false);
    setIsThinking(false);
    setError(null);

    try {
      await axios.post(`${API_URL}/api/new_chat`, {
        wallet_address: ordinalAddress
      });
    } catch (error) {
      console.error('Error starting new chat:', error);
      setError('Failed to start new chat');
    }
  };

  const formatMessage = (message: string) => {
    const parts = message.split(/(```[\s\S]*?```)/);
    return parts.map((part, index) => {
      if (part.startsWith('```') && part.endsWith('```')) {
        const code = part.slice(3, -3);
        const language = code.split('\n')[0];
        const codeContent = code.split('\n').slice(1).join('\n');
        return (
          <div key={index} className={styles.codeBlock}>
            <div className={styles.codeHeader}>
              <span className={styles.language}>{language}</span>
              <button className={styles.copyBtn} onClick={() => copyToClipboard(codeContent)}>Copy</button>
            </div>
            <pre><code className={`language-${language}`}>{codeContent}</code></pre>
          </div>
        );
      } else {
        return <p key={index} dangerouslySetInnerHTML={{ __html: part.replace(/\n/g, '<br>') }} />;
      }
    });
  };

  const speakMessage = async (text: string) => {
    try {
      if (currentAudio) {
        stopSpeaking();
      }

      const response = await axios.post(`${API_URL}/api/text-to-speech`, {
        text: text
      }, {
        responseType: 'blob'
      });

      const audioBlob = response.data;
      const url = window.URL.createObjectURL(audioBlob);
      const audio = new Audio(url);

      setCurrentAudio(audio);

      audio.onended = () => {
        setCurrentAudio(null);
      };

      audio.play();

    } catch (error) {
      console.error('Error in text-to-speech:', error);
    }
  };

  const stopSpeaking = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }
  };

  const toggleTokenDataPopup = () => {
    setShowTokenDataPopup(!showTokenDataPopup);
    setTokenAddress('');
  };

  const handleOpenChart = (symbol: string, type: 'stock' | 'crypto') => {
    if (showDexScreener) {
      setShowDexScreener(false);
    }
    setShowChartTool(true);
    // Add any additional logic needed for the chart tool
  };

  const handleOpenDexScreener = (contractAddress: string, chain: string) => {
    if (showChartTool) {
      setShowChartTool(false);
    }
    setShowDexScreener(true);
    setDexScreenerContract(contractAddress);
    setDexScreenerChain(chain);
  };

  const handleFetchTokenData = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!tokenAddress) return;

    try {
      setIsThinking(true);
      setShowTokenDataPopup(false);
      const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`);
      const tokenData = response.data.pairs[0]; // Assuming we're interested in the first pair

      if (tokenData) {
        const formattedData = formatTokenData(tokenData);
        displayMessage('System', formattedData);
      } else {
        displayMessage('System', 'No data found for the given token address.');
      }
    } catch (error) {
      console.error("Error fetching token data:", error);
      setError("Error fetching token data. Please try again.");
    } finally {
      setIsThinking(false);
      setTokenAddress('');
    }
  };

  const formatTokenData = (tokenData: any) => `
    <p><strong>Token Data:</strong></p>
    <p>Name: ${tokenData.baseToken.name}</p>
    <p>Price: $${parseFloat(tokenData.priceUsd).toFixed(13)}</p>
    <p>24h Volume: $${tokenData.volume.h24.toFixed(2)}</p>
    <p>Liquidity (USD): $${tokenData.liquidity.usd.toFixed(2)}</p>
    <p>24h Price Change: ${tokenData.priceChange.h24.toFixed(2)}%</p>
    <p>Market Cap: $${tokenData.marketCap ? tokenData.marketCap.toFixed(2) : 'N/A'}</p>
`;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Code copied to clipboard');
      // You can add a visual feedback here, like a toast notification
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };


  const stopConversation = async () => {
    if (!isConversationActive) return;

    // Cancel any ongoing request
    if (currentRequest) {
      currentRequest.abort();
    }

    stopSpeaking();

    setIsConversationActive(false);
    setIsStopped(true);
    setIsThinking(false);

    try {
      const response = await axios.post(`${API_URL}/api/stop`);

    } catch (error) {
      console.error('Error stopping conversation:', error);
      setError('Failed to stop conversation');
    }
  };

  // Update newChat function:
  const newChat = async () => {
    try {
      if (!ordinalAddress) {
        console.error('No wallet connected');
        return null;
      }

      const newConversationId = Date.now().toString();
      console.log('Creating new conversation with ID:', newConversationId);

      const response = await axios.post(`${API_URL}/api/conversations`, {
        wallet_address: ordinalAddress,
        conversation_id: newConversationId,
        name: 'New Conversation'
      });

      if (response.data?.success) {
        console.log('Successfully created conversation:', newConversationId);

        // Clear states
        setCurrentConversationId(newConversationId);
        setMessages([]);
        setMessageHistory([]);
        setInputMessage('');
        setError(null);
        setIsCurrentAgentA(true);
        setIsConversationActive(false);

        return newConversationId;
      } else {
        console.error('Failed to create conversation:', response.data);
        return null;
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
      setError('Failed to create new chat');
      return null;
    }
  };




  const displayMessage = (agent: string, message: string) => {
    let displayAgent = agent;
    if (!isReviewerEnabled && agent === 'System') {
      displayAgent = 'Argo';
    }
    setMessages(prev => [...prev, { agent: displayAgent, message }]);
  };

  const handleSendMessage = async () => {
    if (!normalInput.trim()) return;

    const messageToSend = normalInput;
    setNormalInput('');

    if (currentRequest) {
      currentRequest.abort();
    }

    const abortController = new AbortController();
    setCurrentRequest(abortController);

    try {
      setError(null);
      setIsThinking(true);

      let conversationId = currentConversationId;

      if (!conversationId) {
        console.log('No conversation ID found, creating new chat');
        conversationId = await newChat();

        if (!conversationId) {
          throw new Error('Failed to create new conversation');
        }
      }

      console.log('Sending message with conversation ID:', conversationId);

      displayMessage('User', messageToSend);

      const response = await axios.post(`${API_URL}/api/send_message`, {
        message: messageToSend,
        isInitial: !isConversationActive,
        wallet_address: ordinalAddress,
        conversation_id: conversationId,
        messageHistory: messages.map(msg => ({
          role: msg.agent.toLowerCase() === 'user' ? 'user' : 'assistant',
          content: msg.message
        }))
      });

      console.log('Received response:', response.data);

      if (response.data.message) {
        displayMessage('Argo', response.data.message);
        setIsConversationActive(true);

        if (voiceEnabled) {
          await speakMessage(response.data.message);
        }
      }

    } catch (error) {
      console.error('Error sending message:', error);
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setError(`Error: ${error.response.data.error}`);
      } else {
        setError('Failed to send message');
      }
    } finally {
      if (abortController === currentRequest) {
        setCurrentRequest(null);
      }
      setIsThinking(false);
    }
  };


  // Add the agent message handler
  const handleAgentMessage = async () => {
    try {
      console.log('Starting agent message handler');
      console.log('Current Agent:', currentAgent);
      console.log('Conversation Length:', agentConversation.length);
      console.log('Is Thinking:', isThinking);

      setIsThinking(true);
      const isFirstMessage = agentConversation.length === 0;

      // For first message, use user input; for subsequent messages, use last conversation message
      const messageToSend = isFirstMessage ? agentInput : agentConversation[agentConversation.length - 1].message;
      console.log('Message to send:', messageToSend);

      const response = await fetch(`${API_URL}/api/agent-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: messageToSend,
          isAgentA: currentAgent === 'A',
          isFirstMessage
        }),
      });

      const data = await response.json();
      console.log('Received response:', data);

      // Add the response to conversation
      const newConversation = [...agentConversation, {
        agent: currentAgent,
        message: data.response
      }];
      setAgentConversation(newConversation);

      // Clear input only on first message
      if (isFirstMessage) {
        setAgentInput('');
      }

      // Check if we're done
      if (data.response.includes('TASK COMPLETE') && currentAgent === 'B') {
        console.log('Task complete detected');
        setIsAgentTaskComplete(true);
        if (newConversation.length >= 2) {
          const finalOutput = newConversation[newConversation.length - 2].message;
          console.log('Final output to save:', finalOutput);
          await saveAgentOutput(finalOutput);
        }
      } else {
        // Switch agents and continue conversation
        const nextAgent = currentAgent === 'A' ? 'B' : 'A';
        console.log('Switching to agent:', nextAgent);

        // Important: Wait for state updates to complete
        await new Promise(resolve => setTimeout(resolve, 500));
        setCurrentAgent(nextAgent);

        // Only trigger B's response after A's first response
        if (currentAgent === 'A' && !isFirstMessage) {
          console.log('Scheduling next agent response');
          setTimeout(() => {
            handleAgentMessage();
          }, 1000);
        }
      }
    } catch (error) {
      console.error('Error in agent message:', error);
      setError('Error processing agent message');
    } finally {
      setIsThinking(false);
    }
  };

  const handleInitialAgentMessage = async () => {
    try {
      setIsAgentThinking(true);

      const response = await fetch(`${API_URL}/api/agent-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: agentInput,
          isAgentA: true,
          isFirstMessage: true
        }),
      });

      const data = await response.json();

      setAgentConversation([{
        agent: 'A',
        message: data.response
      }]);
      setAgentInput('');
      setCurrentAgent('B');

      // Trigger Agent B's response
      setTimeout(() => {
        handleAgentBResponse(data.response);
      }, 1000);
    } catch (error) {
      console.error('Error in initial agent message:', error);
      setError('Error processing agent message');
    } finally {
      setIsAgentThinking(false);
    }
  };

  const handleAgentBResponse = async (previousMessage: string) => {
    try {
      setIsAgentThinking(true);

      const response = await fetch(`${API_URL}/api/agent-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: previousMessage,
          isAgentA: false,
          isFirstMessage: false
        }),
      });

      const data = await response.json();

      setAgentConversation(prev => [...prev, {
        agent: 'B',
        message: data.response
      }]);

      if (data.response.includes('TASK COMPLETE')) {
        setIsAgentTaskComplete(true);
        // Safely get Agent A's last message
        const agentAMessages = agentConversation.filter(msg => msg.agent === 'A');
        if (agentAMessages.length > 0) {
          await saveAgentOutput(agentAMessages[agentAMessages.length - 1].message);
        }
      } else {
        setCurrentAgent('A');
        // Schedule Agent A's response
        setTimeout(() => {
          handleAgentAResponse(data.response);
        }, 1000);
      }
    } catch (error) {
      console.error('Error in Agent B response:', error);
      setError('Error processing agent message');
    } finally {
      setIsAgentThinking(false);

    }
  };

  const handleAgentAResponse = async (previousMessage: string) => {
    try {
      setIsAgentThinking(true);

      const response = await fetch(`${API_URL}/api/agent-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: previousMessage,
          isAgentA: true,
          isFirstMessage: false
        }),
      });

      const data = await response.json();

      setAgentConversation(prev => [...prev, {
        agent: 'A',
        message: data.response
      }]);

      setCurrentAgent('B');
      // Schedule Agent B's response
      setTimeout(() => {
        handleAgentBResponse(data.response);
      }, 1000);
    } catch (error) {
      console.error('Error in Agent A response:', error);
      setError('Error processing agent message');
    } finally {
      setIsAgentThinking(false);

    }
  };



  // Add the save agent output function
  const saveAgentOutput = async (finalOutput: string) => {
    try {
      if (!ordinalAddress) return;
      console.log('Attempting to save agent output:', finalOutput);

      const response = await fetch(`${API_URL}/api/save-agent-output`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          wallet_address: ordinalAddress,
          output: finalOutput,
          name: `Agent Output - ${new Date().toLocaleDateString()}`
        }),
      });

      if (!response.ok) {
        console.error('Failed to save agent output:', response.statusText);
      } else {
        console.log('Agent output saved successfully');
        fetchAgentOutputs(); // Refresh the list of outputs
      }
    } catch (error) {
      console.error('Error saving agent output:', error);
    }
  };




  const toggleMemeChart = () => {
    setShowMemeChartPopup(true);
    if (showChartTool) setShowChartTool(false);
    if (showDexScreener) setShowDexScreener(false);
  };

  const toggleReviewerMode = async () => {
    try {
      setIsReviewerEnabled(prev => !prev);
      const newConversationId = await newChat();

      if (newConversationId) {
        console.log('New conversation created with ID:', newConversationId);

        // Clear states
        setCurrentConversationId(newConversationId);
        setMessages([]);
        setMessageHistory([]);
        setIsCurrentAgentA(true);
        setIsConversationActive(false);
        setIsStopped(false);
        setError(null);
      } else {
        console.error('Failed to create new conversation on reviewer mode toggle');
      }
    } catch (error) {
      console.error('Error toggling reviewer mode:', error);
      setError('Failed to toggle reviewer mode');
    }
  };

  const saveLastAgentMessage = async () => {
    const lastAgentAMessage = [...agentConversation]
      .reverse()
      .find(msg => msg.agent === 'A')?.message;

    if (!ordinalAddress || !lastAgentAMessage) return;

    try {
      const response = await fetch(`${API_URL}/api/save-agent-output`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          wallet_address: ordinalAddress,
          output: lastAgentAMessage,
          name: `Agent A Output - ${new Date().toLocaleDateString()}`
        }),
      });

      if (response.ok) {
        console.log('Agent A output saved successfully');
      } else {
        console.error('Failed to save Agent A output:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving Agent A output:', error);
    }
  };



  const handleMemeChartSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (memeChartContract) {
      setShowMemeChartPopup(false);
      setShowDexScreener(true);
      setDexScreenerContract(memeChartContract);
      setDexScreenerChain(memeChartChain);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && normalInput.trim()) {
      e.preventDefault();  // Stop the newline from being added
      handleSendMessage();
    }
  };

  const toggleChartTool = () => {
    setShowChartTool(!showChartTool);
    if (!showChartTool) {
      setChartSymbol('BTCUSD');
      setChartType('crypto');
    }
    if (showDexScreener) {
      setShowDexScreener(false);
    }
  };

  const toggleLibrary = () => {
    setShowLibrary(!showLibrary);
  };

  const toggleDexScreener = () => {
    setShowDexScreenerPopup(true);
    if (showChartTool) setShowChartTool(false);
    if (showMemeChartPopup) setShowMemeChartPopup(false);
  };

  const handleDexScreenerSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (dexScreenerContract) {
      setShowDexScreenerPopup(false);
      setShowDexScreener(true);
    }
  };


  return (
    <div className={styles.pageContainer}>
      {!connected ? (
        <div className={styles.connectPrompt}>
          <button onClick={openWalletModal} className={styles.connectButton}>
            Connect Wallet
          </button>
          <p>Please connect your wallet to access Argo Ai.</p>
        </div>
      ) : ownedNFTs.length > 0 ? (
        <><ConversationLibrary
          show={showLibrary}
          walletAddress={ordinalAddress}
          onSelectConversation={loadConversation}
          onClose={() => setShowLibrary(false)}
          agentOutputs={agentOutputs}
          onViewOutput={handleViewAgentOutput}
        />
          <div className={styles.chatContainer}>
            <div className={styles.buttonContainer} ref={dropdownRef}>
              <h2 className={styles.headerbtn}>Argo Ai</h2>
              <button onClick={toggleDropdown} className={styles.functionBtn}>
                Options
              </button>
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  <button onClick={() => handleOptionClick(handleNewChat)} className={styles.dropdownItem}>New Chat</button>
                  <button onClick={() => handleOptionClick(toggleChartTool)} className={styles.dropdownItem}>Chart Tool</button>
                  <button onClick={() => handleOptionClick(toggleMemeChart)} className={styles.dropdownItem}>MemeChart</button>
                  <button onClick={() => handleOptionClick(toggleTokenDataPopup)} className={styles.dropdownItem}>Fetch Token Data</button>
                  <button onClick={() => handleOptionClick(() => setVoiceEnabled(!voiceEnabled))} className={styles.dropdownItem}>
                    Speech {voiceEnabled ? 'Off' : 'On'}
                  </button>
                  <button onClick={() => handleOptionClick(() => setIsAgentMode(true))} className={styles.dropdownItem}>Agents</button>
                  <button onClick={() => handleOptionClick(handleDisconnect)} className={styles.dropdownItem}>Disconnect Wallet</button>
                </div>
              )}

            </div>
            <div className={styles.contentContainer}>


              {/* Display Selected Agent Output */}
              {selectedAgentOutput && (
                <div className={`${styles.message} ${styles.agentOutput}`}>
                  <strong>Agent Output:</strong>
                  <div>{formatMessage(selectedAgentOutput)}</div>
                </div>
              )}
              <div className={styles.messagesContainer} ref={chatContainerRef}>
                {messages.map((msg, index) => (
                  <div key={index} className={`${styles.message} ${styles[msg.agent.toLowerCase().replace(' ', '-')]}`}>
                    <strong>{msg.agent}:</strong>
                    {formatMessage(msg.message)}
                  </div>
                ))}
                {isThinking && (
                  <div className={`${styles.message} ${styles.thinking}`}>
                    <strong>Thinking</strong>
                    <span className={styles.dotAnimation}>
                      <span>.</span><span>.</span><span>.</span>
                    </span>
                  </div>
                )}
                {error && <div className={styles.errorMessage}>{error}</div>}
              </div>
              {showChartTool && (
                <div className={styles.chartToolContainer}>
                  <button
                    className={styles.closeButton}
                    onClick={() => setShowChartTool(false)}
                  >
                    ×
                  </button>
                  <ChartTool symbol={chartSymbol} assetType={chartType} />
                </div>
              )}
              {showDexScreener && dexScreenerContract && (
                <div className={styles.dexScreenerContainer}>
                  <DexScreenerEmbed
                    contractAddress={dexScreenerContract}
                    chain={dexScreenerChain}
                  />
                </div>
              )}
            </div>
            <div className={styles.inputContainer}>
              <textarea
                value={normalInput}
                onChange={(e) => setNormalInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter topic here..."
                className={styles.messageInput}
              />
              <button onClick={handleSendMessage} className={styles.actionButton}>
                {isConversationActive ? 'Send' : 'Start'}
              </button>
              <button onClick={stopConversation} className={styles.actionButton}>Stop</button>
            </div>
          </div>

          <WatchlistPanel
            onOpenDexScreener={handleOpenDexScreener}
            ordinalAddress={ordinalAddress}  // Add this line
          />

          {/* Agent Modal */}
          {isAgentMode && (
            <div className={styles.agentModal}>
              <div className={styles.agentModalContent}>
                <button
                  className={styles.closeButton}
                  onClick={() => {
                    setIsAgentMode(false);
                    setAgentConversation([]);
                    setIsAgentTaskComplete(false);
                    setCurrentAgent('A');
                    setIsAgentThinking(false);
                    setAgentInput('');
                  }}
                >
                  ×
                </button>
                <div className={styles.agentMessages}>
                  {agentConversation.map((msg, index) => (
                    <div key={index} className={styles[`agent${msg.agent}`]}>
                      <strong>{msg.agent === 'A' ? 'Agent A:' : 'Agent B:'}</strong>
                      <div className={styles.messageContent}>{msg.message}</div>
                    </div>
                  ))}
                  {isAgentThinking && <div className={styles.thinking}>Thinking...</div>}
                </div>
                {!isAgentTaskComplete && agentConversation.length === 0 && (
                  <div className={styles.agentInput}>
                    <input
                      type="text"
                      value={agentInput}
                      onChange={(e) => setAgentInput(e.target.value)}
                      placeholder="Enter your task..."
                      disabled={isAgentThinking}
                      onKeyPress={(e) =>
                        e.key === 'Enter' &&
                        !isAgentThinking &&
                        agentInput.trim() &&
                        handleInitialAgentMessage()
                      }
                    />
                    <button
                      onClick={handleInitialAgentMessage}
                      disabled={isAgentThinking || !agentInput.trim()}
                    >
                      Start
                    </button>
                  </div>
                )}
                {isAgentTaskComplete && (
                  <button onClick={saveLastAgentMessage} className={styles.saveButton}>
                    Save Last Agent A Message
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      ) : isLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}></div>
          <p>Checking access...</p>
        </div>
      ) : (
        <div className={styles.connectPrompt}>
          <p>You do not have access to Argo Ai. You need to own an Argo the Puppy ordinal to access this feature.</p>
          <button onClick={handleDisconnect} className={styles.disconnectButton}>
            Disconnect Wallet
          </button>
        </div>
      )}

      {/* Modals */}
      <WalletModal isOpen={isWalletModalOpen} onClose={closeWalletModal} onConnect={handleWalletConnect} />

      {showMemeChartPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <button
              className={styles.closeButton}
              onClick={() => setShowMemeChartPopup(false)}
            >
              ×
            </button>
            <h2>Enter MemeChart Details</h2>
            <form onSubmit={handleMemeChartSubmit}>
              <input
                type="text"
                placeholder="Contract Address"
                value={memeChartContract}
                onChange={(e) => setMemeChartContract(e.target.value)}
                required
              />
              <select value={memeChartChain} onChange={(e) => setMemeChartChain(e.target.value)}>
                <option value="ethereum">Ethereum</option>
                <option value="bsc">BSC</option>
                <option value="polygon">Polygon</option>
                <option value="solana">Solana</option>
              </select>
              <button type="submit">Submit</button>
              <button type="button" onClick={() => setShowMemeChartPopup(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {showTokenDataPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <h2>Enter Token Details</h2>
            <form onSubmit={handleFetchTokenData}>
              <input
                type="text"
                placeholder="Token Address"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                required
              />
              <button type="submit">Submit</button>
              <button type="button" onClick={toggleTokenDataPopup}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;