import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styles from '../styles/Home.module.css'
import Map2 from '../components/map2';
import MobileMap2 from '../components/map3';



const Home: React.FC = () => {
  return (
    <>
      <div className={styles.homepage}>
        <Map2/>
      </div>

      <div className={styles.mobilehomepage}>
        <MobileMap2/>
      </div>
    </>

  );
}

export default Home;
